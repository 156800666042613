<template>
  <div>
    <div class="rectangle-15">
      <div>

      </div>
    </div>
    <div class="rectangle-16">
      <Rectangle16 />
    </div>
    <div class="rectangle-17">
      <div></div>
    </div>
    <div class="rectangle-18">
      <Rectangle18 />
    </div>
  </div>
</template>

<script>
import Rectangle18 from '@/assets/svg/Rectangle18.svg';
import Rectangle16 from '@/assets/svg/Rectangle16.svg';

export default {
  name: "Rectangles",
  components: {
    Rectangle18,
    Rectangle16
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
